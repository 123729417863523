.custom-container {
  // max-width: 100% !important;
  // height: 100% !important;
  width: 90% !important;
  margin-top: 40px;
  min-width: 100% !important;
  margin-bottom: 100px;
}

.sgm-market-search-sports-book {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  box-shadow: 0 0 0 2px var(--b);
  background: var(--b);
  z-index: 1;
}