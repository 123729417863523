.swiper-custom {
  width: 100%;
}

.swiper-custom-left {
  right: 40px !important;
}

.swiper-custom-right {
    right: -40px;
}
